/* ======================================================================== */
/* 27. footerLogo */
/* ======================================================================== */
[data-arts-footer-logo="primary"]
	.logo__img-primary
		opacity: 1
		visibility: visible
	.logo__img-secondary
		opacity: 0
		visibility: hidden
[data-arts-footer-logo="secondary"]
	.logo__img-primary
		opacity: 0
		visibility: hidden
	.logo__img-secondary
		opacity: 1
		visibility: visible
